<template>
  <div>
    <v-container fluid>
      <v-app>
        <HeadText type='Dashboard'>

        </HeadText>
        <v-main>

            <router-view />

        </v-main>


        <Footer></Footer>
      </v-app>
    </v-container>
  </div>

</template>

<script>
import HeadText from "@/components/home/HomeAppBar";
import Footer from "@/components/home/Footer";

export default {
  name: 'DashboardTemplate',
  components: {
    HeadText,
    Footer,
  },

}
</script>